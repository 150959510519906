import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Plugins} from '@capacitor/core';
import { ToastController, Platform, MenuController } from '@ionic/angular';
import { UserAdmin } from './user';
import { Cliente } from './cliente';
import { Negozio } from './negozio';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any;
  cliente: Cliente;
  negozio: Negozio;
  token;
  authSub;
  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,  
    public ngZone: NgZone, private http: HttpClient ,private toastController: ToastController,
    private platform: Platform,
    public menuCtrl: MenuController
  ) {}

  async checkUser() {
    if(this.authSub)
      this.authSub.unsubscribe();
    return new Promise(resolve => {
      this.authSub = this.ngFireAuth.authState.subscribe(async user => {
        if (user) {
          if(user.emailVerified) {
            this.userData = user;
            await this.getUserData();
            resolve(user);
          }   
          else {
            this.userData = user;        
            resolve(user);
            this.router.navigateByUrl("/verify-email");
          }
                   
        } else {
          this.userData=null;
          this.cliente=null;
          this.negozio=null;
          Storage.clear();
          console.log(this.router.url);
          if(this.router.url.startsWith("/registrati") || this.router.url.startsWith("/invita-un-amico") || this.router.url.startsWith("/verify-email"))
            this.router.navigateByUrl(this.router.url);
          else  
            this.router.navigateByUrl("/login");
        }
      }, (err) => {
          resolve(false);
      });
    });   
  } 

  async getUserData() {
    let headers = await this.createHeaders();
    return new Promise(resolve => {    
      const userData = this.http.post('https://d4zxyev036.execute-api.eu-west-1.amazonaws.com/prod/getUserData', null, { headers:  headers }).subscribe((data: any) => {   
      userData.unsubscribe();
      if(!!data['records'] && data['records'].length>0) {
            let resp = data['records'][0];
            this.cliente = {
              id: resp.id,
              nome: resp.nomecli,
              cognome: resp.cognome,
              cf: resp.cf,
              oros_mens: resp.oros_mens,
              prov_id: resp.prov_id,
              prov_nome: resp.prov_nome,
              cv: resp.cv,
              cp: resp.cp,
              oros_gest: resp.oros_gest,
              notifiche: resp.notcli,
              consulente: resp.id_agente,
              tipoCons:resp.tipocons,
              cp_fido:resp.cp_fido,
              valido:resp.valido,
              attivo:resp.attivo,
              max_oros_mens:resp.max_oros_mens,
              foto: resp.foto,
              nmr_negozi: resp.nmr_negozi
              
            }
            if(!!resp.idn) {
              this.negozio = {
                id_negozio:resp.idn,
                nome_negozio: resp.nomen,
                not_neg: resp.not_neg,
                cb: resp.cb,
                cb2:resp.cb2,
                transato:resp.transato,
                credito:resp.credito,
                commissioni:resp.commissioni,
                fido:resp.fido,
                nmr_tr:resp.nmr_tr,
                perc:resp.perc_comp,
                ruolo_neg: resp.ruolo_neg,
                scarico: resp.scarico
              }  
            }
            resolve(true); 
            if(this.router.url.startsWith("/registrati") || this.router.url.startsWith("/invita-un-amico") || this.router.url.startsWith("/verify-email"))
              this.router.navigateByUrl(this.router.url);
            else
              this.router.navigateByUrl("home-negozio");
             
          }
          else {
            this.router.navigateByUrl("/forbidden");
          }         
        }, (err) => {
          userData.unsubscribe();
          resolve(false);
        });  
    });  
     

  }

  async refreshUserData() {
    let headers = await this.createHeaders();
    return new Promise(resolve => {    
      const userData = this.http.post('https://d4zxyev036.execute-api.eu-west-1.amazonaws.com/prod/getUserData', null, { headers:  headers }).subscribe((data: any) => {   
      userData.unsubscribe();
      if(!!data['records'] && data['records'].length>0) {
            let resp = data['records'][0];
            this.cliente = {
              id: resp.id,
              nome: resp.nomecli,
              cognome: resp.cognome,
              cf: resp.cf,
              oros_mens: resp.oros_mens,
              prov_id: resp.prov_id,
              prov_nome: resp.prov_nome,
              cv: resp.cv,
              cp: resp.cp,
              oros_gest: resp.oros_gest,
              notifiche: resp.notcli,
              consulente: resp.id_agente,
              tipoCons:resp.tipocons,
              cp_fido:resp.cp_fido,
              valido:resp.valido,
              attivo:resp.attivo,
              max_oros_mens:resp.max_oros_mens,
              foto: resp.foto,
              nmr_negozi: resp.nmr_negozi
            }
            if(!!resp.idn) {
              this.negozio = {
                id_negozio:resp.idn,
                nome_negozio: resp.nomen,
                not_neg: resp.not_neg,
                cb: resp.cb,
                cb2:resp.cb2,
                transato:resp.transato,
                credito:resp.credito,
                commissioni:resp.commissioni,
                fido:resp.fido,
                nmr_tr:resp.nmr_tr,
                perc:resp.perc_comp,
                ruolo_neg: resp.ruolo_neg,
                scarico: resp.scarico
              }
            } 

            resolve(true);  
          }
        }, (err) => {
          userData.unsubscribe();
          resolve(false);
        });  
    });  
     

  }

  async changeNegozioData(id,ruolo) {   
    let headers = await this.createHeaders();
    return new Promise(resolve => {    
      const userData = this.http.post('https://d4zxyev036.execute-api.eu-west-1.amazonaws.com/prod/getNegozioData', {id: !!id ? id : this.negozio.id_negozio}, { headers:  headers }).subscribe((data: any) => {   
      userData.unsubscribe();
      if(!!data['records'] && data['records'].length>0) {
            let resp = data['records'][0];
            if(resp.idn>0) {
              this.ngZone.run(()=>{
                this.negozio = {
                  id_negozio:resp.idn,
                  nome_negozio: resp.nomen,
                  not_neg: resp.not_neg,
                  cb: resp.cb,
                  cb2:resp.cb2,
                  transato:resp.transato,
                  credito:resp.credito,
                  commissioni:resp.commissioni,
                  fido:resp.fido,
                  nmr_tr:resp.nmr_tr,
                  perc:resp.perc_comp,
                  ruolo_neg: ruolo<0 ? this.negozio.ruolo_neg : ruolo,
                  scarico: resp.scarico
                }   
              });
              
            }
            
            resolve(true);  
          }
        }, (err) => {
          userData.unsubscribe();
          resolve(false);
        });  
    });  
     

  }

  async createHeaders(): Promise<any> {
    if(!this.userData)
      await this.checkUser();
    var promise = new Promise((resolve, reject) => {
     this.userData.getIdToken(true).then(function(idToken) { 
       let headers = new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': `Bearer `+idToken,
       });
       resolve(headers);
     });  
    });
    return promise;
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(cred => {
        if(!!this.userData && cred.user.emailVerified) 
          this.getUserData();
        else if(!!cred) {
          if(!cred.user.emailVerified) 
            this.router.navigate(['verify-email']);                 
        }
        else
          this.presentToast('Email e/o password errate','top',3000);

          return cred;
        
      }).catch((error) => {
        this.presentToast('Email e/o password errate','top',3000);
        return null;
      })
  }

   // Register user with email/password
   RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password).then(cred => {
      return cred;
    }).catch((error) => {
      this.presentToast("Già esiste un account creato con l'email "+email,'top',3000);
    })
  }

  async SendVerificationMail() {
    return (await this.ngFireAuth.currentUser).sendEmailVerification()
    .then(() => {
      console.log("GO TO VERIFY EMAIL");
      this.router.navigateByUrl('/verify-email');
    })
  }


  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.presentToast('Abbiamo inviato sulla tua email un link per resettare la tua password','top',10000);   
    }).catch((error) => {
     // window.alert(error)
    })
  }

  async presentToast(message, position, duration) {
    const toast = await this.toastController.create({
      message,
      buttons: [
        {
          text: 'Chiudi',
          role: 'cancel',
          handler: () => {
          }
        }
      ],
      position,
      duration
    });
    toast.present();
  }

  // Sign-out 
  async SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userData=null;
      this.cliente=null;
      this.negozio=null;
      this.router.navigateByUrl("/login");
    })
  }

  
}


