import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, public authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(!this.authService.cliente)
      this.router.navigateByUrl("/login");
    else if(this.authService.negozio?.id_negozio<0) {
      this.router.navigateByUrl("/forbidden");
      return false;
    } 

    return true;
  }
}
