import { Component, OnInit } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { Router, NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  active=0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthService,
    private menu: MenuController,
    private router: Router
  ) {
    registerLocaleData(localeIT);
    this.initializeApp();
  }

  async initializeApp() {
    await this.authService.checkUser();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {

  }

  goToPage(url,active) {
    this.active=active;
  /*  this.sidemenuLayout1.forEach(element => {
      if(element.url==url)
        element.active=true;
      else
        element.active=false;
    }); */
    if(url=="logout") {
      this.authService.SignOut();
      this.menu.close();
    }
    else if(url=="circuito-home") {
      let navigationExtras: NavigationExtras = {
        state: {
          provincia: this.authService.cliente.prov_nome
        }
      };
      this.router.navigate(['/circuito-home'], navigationExtras);
      this.menu.close();
    }
    else if(url=="area-consulente/tabs/guadagni") {
      let navigationExtras: NavigationExtras = {
        state: {
          id: this.authService.cliente.consulente
        }
      };
      this.router.navigate(['/area-consulente/tabs/guadagni'], navigationExtras);
      this.menu.close();
    }
    else {
      this.router.navigateByUrl(url);
      this.menu.close();
    }

  }
}
