import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'waiting',
    pathMatch: 'full'
  },
  {
    path: 'waiting',
    loadChildren: () => import('./waiting/waiting.module').then( m => m.WaitingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-negozio',
    loadChildren: () => import('./aderenti/home-negozio/home-negozio.module').then( m => m.HomeNegozioPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./pages/forbidden/forbidden.module').then( m => m.ForbiddenPageModule)
  },
  {
    path: 'registrati',
    loadChildren: () => import('./pages/registrati/registrati.module').then( m => m.RegistratiPageModule)
  },
  {
    path: 'invita-un-amico',
    loadChildren: () => import('./pages/invita-un-amico/invita-un-amico.module').then( m => m.InvitaUnAmicoPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'circuito',
    loadChildren: () => import('./circuito/circuito.module').then( m => m.CircuitoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
